export const POOL_ISO = 'isolated'
export const POOL_REC = 'rec'

export const GPS_CALC_AUTO_SELECTION_OPTIONS = [
	{ text: 'No', value: -1 },
	{
		text: 'All recommendations',
		value: 'all_rec',
	},
	{
		text: "All recommendations Less Than or Equal To Usage Products' Price",
		value: 'le_up',
	},
	{
		text: "All recommendations within 10% of Usage Products' Price",
		value: 'le_up_10',
	},
	{
		text: "All recommendations within 25% of Usage Products' Price",
		value: 'le_up_25',
	},
	{
		text: "All recommendations within 50% of Usage Products' Price",
		value: 'le_up_50',
	},
	{
		text: "All recommendations within 100% of Usage Products' Price",
		value: 'w_100',
	},
]
export const REC_DEFAULT_FILTER = {
	'3m': [
		{
			class_name: 'company',
			vals: ['3M'],
		},
	],
	olympus: [
		{
			class_name: 'company',
			vals: ['OLYMPUS'],
		},
		{
			class_name: 'status',
			vals: ['ACTIVE'],
		},
	],
	conmed: [
		{
			class_name: 'company',
			vals: ['CONMED'],
		},
		{
			class_name: 'status',
			vals: ['ACTIVE'],
		},
	],
	anika: [
		{
			class_name: 'company',
			vals: ['ANIKA'],
		},
		{
			class_name: 'status',
			vals: ['ACTIVE'],
		},
	],
	karlstorz: [
		{
			class_name: 'company',
			vals: ['KARL STORZ']
		}
	],
	biotronik: [
		{
			class_name: 'company',
			vals: ['BIOTRONIK']
		}
	]
}
